import { React } from "react"
import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next"
import TranslateButton from "./functions/translatebutton"
import styles from "./Header.module.scss"
import { Back } from "../components/icons"
import Logo from "../assets/images/erasmuslogo.png"
const Header = () => {
  const [t] = useTranslation("common")

  return (
    <header>
      <nav className={styles.link}>
        <a className="back" href={t("nav.erasmus_impacttool_url")}>
          <Back />
        </a>
        <Link to={t("nav.home_url")}>
          <img src={Logo} alt="Erasmus + Logo" className={styles.logo} />
        </Link>
      </nav>
      <TranslateButton />
    </header>
  )
}

export default Header
