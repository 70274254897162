import { React, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styles from "./Footer.module.scss"
import { useLocation } from "react-router-dom"
// import { Back } from "../components/icons"
import inputIcon from "./icons/input.svg"
import activitiesIcon from "./icons/activities.svg"
import outputIcon from "./icons/output.svg"
import outcomeIcon from "./icons/outcome.svg"
import impactIcon from "./icons/impact.svg"
import {
  ScrolltoActivity,
  ScrolltoImpact,
  ScrolltoInput,
  ScrolltoOutcome,
  ScrolltoOutput,
} from "./functions/Scrolltoblock"
const Footer = () => {
  const [t] = useTranslation("common")
  const [highlight, setHighlight] = useState()
  const [isColor, setColor] = useState(false)
  const flowchart = document.querySelector("#flowchart")
  const location = useLocation()

  useEffect(() => {
    const flowchart = document.querySelector("#flowchart")
    if (location.search === "?activity") {
      flowchart.className = "highlight-activity"
      setHighlight("highlight")
      setColor("blue")
    } else if (location.search === "?output") {
      flowchart.className = "highlight-output"
      setHighlight("highlight")
      setColor("green")
    } else if (location.search === "?outcome") {
      flowchart.className = "highlight-outcome"
      setHighlight("highlight")
      setColor("orange")
    } else if (location.search === "?impact") {
      flowchart.className = "highlight-impact"
      setHighlight("highlight")
      setColor("pink")
    } else if (location.search === "?input") {
      flowchart.className = "highlight-input"
      setHighlight("highlight")
      setColor("darkblue")
    }
  }, [location])

  const setBlue = () => {
    if (flowchart.classList.value === "highlight-activity") {
      flowchart.classList.remove("highlight-activity")
      setColor()
      setHighlight()
    } else {
      flowchart.classList = "highlight-activity"
      setHighlight("highlight")
      setColor("blue")
      ScrolltoActivity()
    }
  }
  const setGreen = () => {
    if (flowchart.classList.value === "highlight-output") {
      flowchart.classList.remove("highlight-output")
      setColor()
      setHighlight()
    } else {
      flowchart.classList = "highlight-output"
      setHighlight("highlight")
      setColor("green")
      ScrolltoOutput()
    }
  }
  const setOrange = () => {
    if (flowchart.classList.value === "highlight-outcome") {
      flowchart.classList.remove("highlight-outcome")
      setColor()
      setHighlight()
    } else {
      flowchart.classList = "highlight-outcome"
      setHighlight("highlight")
      setColor("orange")
      ScrolltoOutcome()
    }
  }
  const setPink = () => {
    if (flowchart.classList.value === "highlight-impact") {
      flowchart.classList.remove("highlight-impact")
      setColor()
      setHighlight()
    } else {
      flowchart.classList = "highlight-impact"
      setHighlight("highlight")
      setColor("pink")
      ScrolltoImpact()
    }
  }
  const setDarkBlue = () => {
    if (flowchart.classList.value === "highlight-input") {
      flowchart.classList.remove("highlight-input")
      setColor()
      setHighlight()
    } else {
      setHighlight("highlight")
      setColor("darkblue")
      flowchart.classList = "highlight-input"
      ScrolltoInput()
    }
  }
  if (location.pathname === "/" || location.pathname === "/en") {
    return null
  } else {
    return (
      <footer className={styles.footer}>
        <ul className={`${highlight === "highlight" ? styles.highlight : ""}`}>
          <li>
            <button
              className={`${isColor === "pink" ? styles.pink : ""}`}
              onClick={() => setPink()}
            >
              <img src={impactIcon} alt={t("footer.impact")} />
              {t("footer.impact")}
            </button>
          </li>
          <li>
            <button
              className={`${isColor === "orange" ? styles.orange : ""}`}
              onClick={() => setOrange()}
            >
              <img src={outcomeIcon} alt={t("footer.outcome")} />
              {t("footer.outcome")}
            </button>
          </li>
          <li>
            <button
              className={`${isColor === "green" ? styles.green : ""}`}
              onClick={() => setGreen()}
            >
              <img src={outputIcon} alt={t("footer.output")} />
              {t("footer.output")}
            </button>
          </li>
          <li>
            <button
              className={`${isColor === "blue" ? styles.blue : ""}`}
              onClick={() => setBlue()}
            >
              <img src={activitiesIcon} alt={t("footer.activities")} />
              {t("footer.activities")}
            </button>
          </li>
          <li>
            <button
              className={`${isColor === "darkblue" ? styles.darkblue : ""}`}
              onClick={() => setDarkBlue()}
            >
              <img src={inputIcon} alt={t("footer.input")} />
              {t("footer.input")}
            </button>
          </li>
        </ul>
      </footer>
    )
  }
}
export default Footer
