import React, { useEffect } from "react"
import { Route, useLocation, Routes, Navigate } from "react-router-dom"
import {
  ScrolltoActivity,
  ScrolltoImpact,
  ScrolltoInput,
  ScrolltoOutcome,
  ScrolltoOutput,
} from "./components/functions/Scrolltoblock"
import KA1 from "./pages/ka1"
import KA2Land from "./pages/ka2-land"
import KA2Relar from "./pages/ka2-relar"
import KA2Youth from "./pages/ka2-youth"
import OverviewPage from "./pages/overview"
// import FCTest from "./pages/test-flowchart"
const Switch = () => {
  const location = useLocation()

  useEffect(() => {
    const flowchart = document.querySelector("#flowchart")
    if (location.search === "?activity") {
      flowchart.className = "highlight-activity"
      setTimeout(() => {
        ScrolltoActivity()
      }, 300)
    } else if (location.search === "?output") {
      flowchart.className = "highlight-output"
      setTimeout(() => {
        ScrolltoOutput()
      }, 300)
    } else if (location.search === "?outcome") {
      flowchart.className = "highlight-outcome"
      setTimeout(() => {
        ScrolltoOutcome()
      }, 300)
    } else if (location.search === "?impact") {
      flowchart.className = "highlight-impact"
      setTimeout(() => {
        ScrolltoImpact()
      }, 300)
    } else if (location.search === "?input") {
      flowchart.className = "highlight-input"
      setTimeout(() => {
        ScrolltoInput()
      }, 300)
    }
  }, [location])

  return (
    <Routes location={location}>
      <Route exact path="/" element={<OverviewPage />} />
      <Route exact path="/en" element={<OverviewPage />} />
      <Route exact path="/ka1" element={<KA1 />} />
      <Route exact path="/en/ka1" element={<KA1 />} />

      <Route exact path="/ka2-land" element={<KA2Land />} />
      <Route exact path="/en/ka2-land" element={<KA2Land />} />
      <Route exact path="/ka2-relar" element={<KA2Relar />} />
      <Route exact path="/en/ka2-relar" element={<KA2Relar />} />
      <Route exact path="/ka2-jeugd" element={<KA2Youth />} />
      <Route exact path="/en/ka2-youth" element={<KA2Youth />} />

      {/* <Route path="/test" element={<FCTest />} /> */}
      {/* 404 pages */}
      <Route path="/*" element={<Navigate to="/" replace />} />
      <Route path="/en/*" element={<Navigate to="/en" replace />} />
    </Routes>
  )
}

export default Switch
