import { React, useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import common_nl from "./data/translations/nl.json"
import common_en from "./data/translations/en.json"
import Switch from "./Switch"
import Header from "./components/Header"
import Footer from "./components/Footer"

const App = () => {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: "nl", // language to use
    returnObjects: true,
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace
      },
      nl: {
        common: common_nl,
      },
    },
  })
  useEffect(() => {
    if (i18next.language === "en") {
      i18next.changeLanguage("en")
    } else {
      i18next.changeLanguage("nl")
    }
  }, [])

    useEffect(() => {
      if (i18next.language === "en") {
        i18next.changeLanguage("en")
      } else {
        i18next.changeLanguage("nl")
      }
    }, [])

  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Header />
        <Switch />

        <Footer />
      </BrowserRouter>
    </I18nextProvider>
  )
}

export default App
