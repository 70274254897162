import React, { useEffect } from "react"
import { Close } from "../components/icons"
import { useTranslation } from "react-i18next"
import styles from "./Modal_module.scss"

const Modal = ({ title, setModal, show, inclusion, digitalisation }) => {
  const [t] = useTranslation("common")
  useEffect(() => {
    if (show) {
      document.onkeydown = evt => {
        if (evt.keyCode === 27) {
          setModal()
        }
      }

      document.querySelector("body").className = "noscroll"
    } else {
      document.querySelector("body").className = ""
    }
  }, [show, setModal])
  return (
    <div className={(styles, `modal`)}>
      <button
        className="modal__close-layer"
        onClick={() => setModal(false)}
      ></button>
      <div className="modal__inner">
        <button className="close" onClick={() => setModal(false)}>
          <Close />
        </button>
        <h2 className="modal__title">{title}</h2>
        <div className="modal__content">
          {(() => {
            if (inclusion !== "") {
              return (
                <div className="modal__content__inclusion">
                  <h3>{`${t("flowchart_general.inclusion")}`}</h3>
                  <p dangerouslySetInnerHTML={{ __html: inclusion }}></p>
                </div>
              )
            } else {
              return
            }
          })()}
          {(() => {
            if (digitalisation !== "") {
              return (
                <div className="modal__content__digitalisation">
                  <h3>{`${t("flowchart_general.digitalisation")}`}</h3>
                  {/* <p>{digitalisation}</p> */}
                  <p dangerouslySetInnerHTML={{ __html: digitalisation }}></p>
                </div>
              )
            } else {
              return
            }
          })()}

          {/* <div
						className='modal__content__text'
						dangerouslySetInnerHTML={{ __html: content }}
					></div> */}
        </div>
      </div>
    </div>
  )
}

export default Modal
