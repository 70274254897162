import { React, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./translatebutton.module.scss"
// import i18next from "i18next"

const TranslateButton = () => {
  const [t, i18n] = useTranslation("common")
  const btn = useRef(null)
  const [activeLan, setActiveLan] = useState()

  useEffect(() => {
    if (window.location.href.substr(0, window.location.href.indexOf("en"))) {
      setActiveLan("en")
      i18n.changeLanguage("en")
    } else {
      setActiveLan("nl")
      i18n.changeLanguage("nl")
    }
  }, [i18n])

  const setNL = () => {
    setActiveLan("nl")

    if (window.location.pathname === "/en") {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", "/")
    }
    if (window.location.pathname === t("nav.ka1_url")) {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", t("nav.ka1_url"))
    }
    if (window.location.pathname === t("nav.ka2_url_land")) {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", t("nav.ka2_url_land"))
    }
    if (window.location.pathname === t("nav.ka2_url_relar")) {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", t("nav.ka2_url_relar"))
    }
    if (window.location.pathname === t("nav.ka2_url_mbo")) {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", t("nav.ka2_url_mbo"))
    }
    if (window.location.pathname === t("nav.ka2_url_hbo")) {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", t("nav.ka2_url_hbo"))
    }
    if (window.location.pathname === t("nav.ka2_url_youth")) {
      i18n.changeLanguage("nl")
      window.history.pushState({}, "", t("nav.ka2_url_youth"))
    }
  }

  const setEN = () => {
    setActiveLan("en")

    if (window.location.pathname === "/flowchart") {
      window.history.pushState({}, "", "/en/flowchart")
    }

    if (window.location.pathname === "/") {
      i18n.changeLanguage("en")
      window.history.pushState({}, "", "/en")
    }
    if (window.location.pathname === t("nav.ka1_url")) {
      i18n.changeLanguage("en")
      window.history.pushState({}, "", t("nav.ka1_url"))
    }
    if (window.location.pathname === t("nav.ka2_url_land")) {
      i18n.changeLanguage("en")
      window.history.pushState({}, "", t("nav.ka2_url_land"))
    }
    if (window.location.pathname === t("nav.ka2_url_relar")) {
      i18n.changeLanguage("en")
      window.history.pushState({}, "", t("nav.ka2_url_relar"))
    }
    if (window.location.pathname === t("nav.ka2_url_hbo")) {
      i18n.changeLanguage("en")
      window.history.pushState({}, "", t("nav.ka2_url_hbo"))
    }
    if (window.location.pathname === t("nav.ka2_url_youth")) {
      i18n.changeLanguage("en")
      window.history.pushState({}, "", t("nav.ka2_url_youth"))
    }
  }

  return (
    <div className={styles.translatebutton}>
      <button
        className={`nl ${activeLan === "nl" ? styles.active : ""}`}
        ref={btn}
        onClick={setNL}
      >
        nl
      </button>
      <span>/</span>
      <button
        className={`en ${activeLan === "en" ? styles.active : ""}`}
        ref={btn}
        onClick={setEN}
      >
        en
      </button>
    </div>
  )
}

export default TranslateButton
