export const ScrolltoActivity = () => {
  if (document.querySelectorAll(".activity-block").length > 1) {
    const block = document
      .querySelectorAll(".activity-block")[0]
      .querySelector("rect")
    ScrolltoBlock(block)
  }
}
export const ScrolltoOutput = () => {
  if (document.querySelectorAll(".output-block").length > 1) {
    const block = document
      .querySelectorAll(".output-block")[0]
      .querySelector("rect")
    ScrolltoBlock(block)
  }
}
export const ScrolltoOutcome = () => {
  if (document.querySelectorAll(".outcome-block").length > 1) {
    const block = document
      .querySelectorAll(".outcome-block")[0]
      .querySelector("rect")
    ScrolltoBlock(block)
  }
}
export const ScrolltoImpact = () => {
  if (document.querySelectorAll(".impact-block").length > 1) {
    const block = document
      .querySelectorAll(".impact-block")[0]
      .querySelector("rect")
    ScrolltoBlock(block)
  }
}
export const ScrolltoInput = () => {
  if (document.querySelectorAll(".input-block").length > 1) {
    const block = document
      .querySelectorAll(".input-block")[0]
      .querySelector("rect")
    ScrolltoBlock(block)
  }
}

const ScrolltoBlock = (block) => {
  var headerOffset = 150
  if (window.innerWidth <= 800) {
    headerOffset = 50
  }

  var elementPosition = block.getBoundingClientRect().top
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}
