import { React } from "react"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
// import { CSSTransition } from "react-transition-group"
// import Modal from "../components/modal"

const Ka2Youth = () => {
  const [t] = useTranslation("common")
  //   const [isModalOpen, setIsModalOpen] = useState(false)
  //   const [modalText_inclusion, SetModalText_inclusion] = useState()
  //   const [modalText_digitalisation, SetModalText_digitalisation] = useState()
  //   const [titleModal, setTitleModal] = useState()

  return (
    <motion.main
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <section id="flowchart">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={1229}
          height={2169}
          viewBox="-0.5 -0.5 1229 2169"
        >
          <defs>
            <style>
              {"@import url(https://fonts.googleapis.com/css?family=Roboto);"}
            </style>
          </defs>
          <g className=" flowchart-item impact-block">
            <rect
              x={830}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 63,
                    marginLeft: 849,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.impact.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={940}
                y={66}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.impact.title1")}`}
              </text>
            </switch>
            {/* <image
              x={839.5}
              y={7.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M494 363.5v-63.21h146v-56.92"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m640 238.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            fill="#c1f5c6"
            pointerEvents="all"
            d="M249 363.5h490v160H249z"
          />

          <path
            d="M640 112V62.48l183.63.02"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m828.88 62.5-7 3.5 1.75-3.5-1.75-3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={530}
              y={112}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 175,
                    marginLeft: 549,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.impact.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={640}
                y={178}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.impact.title2")}`}
              </text>
            </switch>
          </g>
          {/* <image
            x={539.5}
            y={119.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <g className=" flowchart-item impact-block">
            <rect
              x={260}
              y={383}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 446,
                    marginLeft: 279,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.impact.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={370}
                y={449}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.impact.title3")}`}
              </text>
            </switch>
            {/* <image
              x={269.5}
              y={390.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <g className=" flowchart-item impact-block">
            <rect
              x={500}
              y={382}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 445,
                    marginLeft: 519,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.impact.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={610}
                y={448}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.impact.title4")}`}
              </text>
            </switch>
            {/* <image
              x={509.5}
              y={389.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M488 587v-31.76l.1-24.41"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m488.12 525.58 3.47 7.01-3.49-1.76-3.51 1.73Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={378}
              y={587}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 650,
                    marginLeft: 397,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={488}
                y={653}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title1")}`}
              </text>
            </switch>
            {/* <image
              x={387.5}
              y={594.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M488 762v-43.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m488 713.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={378}
              y={762}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 825,
                    marginLeft: 397,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={488}
                y={828}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title2")}`}
              </text>
            </switch>
          </g>
          {/* <image
            x={387.5}
            y={769.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <path
            d="M793 824.5H604.37"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m599.12 824.5 7-3.5-1.75 3.5 1.75 3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={793}
              y={762}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 825,
                    marginLeft: 812,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={903}
                y={828}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title3")}`}
              </text>
            </switch>
            {/* <image
              x={802.5}
              y={769.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M903 935v-41.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m903 888.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M793 997.52h-97.48l-91.15.89"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m599.12 998.47 6.96-3.57-1.71 3.51 1.78 3.49Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={793}
              y={935}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 998,
                    marginLeft: 812,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={903}
                y={1001}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title4")}`}
              </text>
            </switch>
            {/* <image
              x={802.5}
              y={942.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M488 936v-42.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m488 888.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={378}
              y={936}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 999,
                    marginLeft: 397,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title5")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={488}
                y={1002}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title5")}`}
              </text>
            </switch>
            {/* <image
              x={387.5}
              y={943.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M280 998.5h91.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m376.88 998.5-7 3.5 1.75-3.5-1.75-3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={60}
              y={936}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 999,
                    marginLeft: 79,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title6")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={170}
                y={1002}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title6")}`}
              </text>
            </switch>
            {/* <image
              x={69.5}
              y={943.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M488 1105v-37.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m488 1062.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M598 1167.52h305.05v-101.15"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m903.05 1061.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={378}
              y={1105}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1168,
                    marginLeft: 397,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title7")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={488}
                y={1171}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title7")}`}
              </text>
            </switch>
            {/* <image
              x={387.5}
              y={1112.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="m755 1341.5-143 .02-132 .48"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={755}
              y={1279}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#f66700"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1342,
                    marginLeft: 774,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.outcome.title8")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={865}
                y={1345}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.outcome.title8")}`}
              </text>
            </switch>
            {/* <image
              x={760.5}
              y={1286.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M865 1482v-71.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m865 1405.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path fill="#c1f5c6" pointerEvents="all" d="M620 1482h490v160H620z" />
          <g className="flowchart-item output-block">
            <rect
              x={640}
              y={1499.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a738"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1562,
                    marginLeft: 659,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.output.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={750}
                y={1566}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.output.title1")}`}
              </text>
            </switch>
            {/* <image
              x={649.5}
              y={1507}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <g className="flowchart-item output-block">
            <rect
              x={873}
              y={1500.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a738"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1563,
                    marginLeft: 892,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.output.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={983}
                y={1567}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.output.title2")}`}
              </text>
            </switch>
            {/* <image
              x={882.5}
              y={1508}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <g className="flowchart-item output-block">
            <path
              d="M410 1449v-107h78v-105.63"
              fill="none"
              stroke="#000"
              strokeMiterlimit={10}
              pointerEvents="stroke"
            />
            <path
              d="m488 1231.12 3.5 7-3.5-1.75-3.5 1.75Z"
              stroke="#000"
              strokeMiterlimit={10}
              pointerEvents="all"
            />
            <rect
              x={300}
              y={1410}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a738"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1473,
                    marginLeft: 319,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.output.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={410}
                y={1476}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.output.title3")}`}
              </text>
            </switch>
            {/* <image
              x={309.5}
              y={1417.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M110 1410v-174.48h60v-168.15"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m170 1062.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item output-block">
            <rect
              y={1410}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a738"
              stroke="#6b6b6b"
              pointerEvents="all"
            />

            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1473,
                    marginLeft: 19,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.output.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={110}
                y={1476}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.output.title4")}`}
              </text>
            </switch>
            {/* <image
              x={9.5}
              y={1417.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M983 1712v-80.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m983 1626.62 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path fill="#c1f5c6" pointerEvents="all" d="M738 1712h490v160H738z" />
          <g className="flowchart-item activity-block">
            <rect
              x={758}
              y={1729.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a6df"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1792,
                    marginLeft: 777,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.activity.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={868}
                y={1796}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.activity.title1")}`}
              </text>
            </switch>
            {/* <image
              x={767.5}
              y={1737}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <g className="flowchart-item activity-block">
            <rect
              x={991}
              y={1728.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a6df"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1791,
                    marginLeft: 1010,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.activity.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={1101}
                y={1795}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.activity.title2")}`}
              </text>
            </switch>
            {/* <image
              x={1000.5}
              y={1738}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <g className="flowchart-item activity-block">
            <path
              d="M540 1727v-51.29h210v-44.84"
              fill="none"
              stroke="#000"
              strokeMiterlimit={10}
              pointerEvents="stroke"
            />
            <path
              d="m750 1625.62 3.5 7-3.5-1.75-3.5 1.75Z"
              stroke="#000"
              strokeMiterlimit={10}
              pointerEvents="all"
            />
            <rect
              x={430}
              y={1727}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a6df"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1790,
                    marginLeft: 449,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.activity.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={540}
                y={1793}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.activity.title3")}`}
              </text>
            </switch>
            {/* <image
              x={439.5}
              y={1734.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M210 1717v-91H110v-84.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m110 1536.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M210 1717v-91h200v-84.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m410 1536.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={100}
              y={1717}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a6df"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1780,
                    marginLeft: 119,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.activity.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={210}
                y={1783}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.activity.title4")}`}
              </text>
            </switch>
            {/* <image
              x={109.5}
              y={1724.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M790 1950v-38H540v-53.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m540 1853.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M790 1950v-38h193.05l-.04-33.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m983 1873.12 3.51 6.99-3.5-1.74-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={680}
              y={1950}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a6df"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 2013,
                    marginLeft: 699,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.activity.title5")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={790}
                y={2016}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.activity.title5")}`}
              </text>
            </switch>
            {/* <image
              x={689.5}
              y={1957.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
          <path
            d="M350 2042v-100H210v-93.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m210 1843.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M460 2104.48h330v-23.11"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m790 2076.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={240}
              y={2042}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a6df"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 2105,
                    marginLeft: 259,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-youth.activity.title6")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={350}
                y={2108}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-youth.activity.title6")}`}
              </text>
            </switch>

            {/*<image
              x={249.5}
              y={2050.5}
              width={20}
              height={20}
              xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
              preserveAspectRatio="none"
            /> */}
          </g>
        </svg>
      </section>
      {/* <CSSTransition
        in={isModalOpen}
        timeout={200}
        classNames="modal"
        unmountOnExit
      >
        <Modal
          show={isModalOpen}
          setModal={setIsModalOpen}
          title={titleModal}
          inclusion={modalText_inclusion}
          digitalisation={modalText_digitalisation}
        />
      </CSSTransition> */}
    </motion.main>
  )
}

export default Ka2Youth
