import { React, useState } from "react"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import { CSSTransition } from "react-transition-group"
import Modal from "../components/modal"

const KA2Relar = () => {
  const [t] = useTranslation("common")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText_inclusion, SetModalText_inclusion] = useState()
  const [modalText_digitalisation, SetModalText_digitalisation] = useState()
  const [titleModal, setTitleModal] = useState()

  return (
    <motion.main
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <section id="flowchart">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={1241}
          height={2011}
          viewBox="-0.5 -0.5 1241 2011"
        >
          <defs>
            <style>
              {"@import url(https://fonts.googleapis.com/css?family=Roboto);"}
            </style>
          </defs>
          <path
            d="M230 580v-81.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m230 491.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M470 580v-45h105v-36.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m575 491.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path fill="#c1f5c6" pointerEvents="all" d="M110 580h480v160H110z" />
          <path
            d="M350 939.5v-20 5.5-11.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m350 906.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            fill="#c1f5c6"
            pointerEvents="all"
            d="M110 939.5h480v160H110z"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={780}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 63,
                    marginLeft: 799,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.impact.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={890}
                y={66}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.impact.title1")}`}
              </text>
            </switch>
          </g>
          <g
            className=" flowchart-item outcome-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.outcome.text1.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.outcome.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.outcome.title1")}`)
            }
          >
            <rect
              x={360}
              y={597.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />

            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 660,
                    marginLeft: 379,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.outcome.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={470}
                y={664}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.outcome.title1")}`}
              </text>
            </switch>
          </g>
          <path
            d="M860 1290v-36.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m860 1246.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block ">
            <rect
              x={750}
              y={1290}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1353,
                    marginLeft: 769,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.activity.title1")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={860}
                y={1356}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title1")}`}
              </text>
            </switch>
          </g>
          <g
            className="flowchart-item output-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.output.text1.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.output.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.output.title1")}`)
            }
          >
            <rect
              x={118}
              y={959.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1022,
                    marginLeft: 137,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.output.title1")}`}
                        <br
                          style={{
                            fontSize: 13,
                          }}
                        />
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={228}
                y={1026}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.output.title1")}`}
              </text>
            </switch>
          </g>
          <path
            d="M770 202.5h120v-69.38"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m890 126.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item impact-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.impact.text2.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.impact.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.impact.title2")}`)
            }
          >
            <rect
              x={550}
              y={140}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 203,
                    marginLeft: 569,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.impact.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={660}
                y={206}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.impact.title2")}`}
              </text>
            </switch>
          </g>
          <g
            className=" flowchart-item impact-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.impact.text3.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.impact.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.impact.title3")}`)
            }
          >
            <path
              d="M630 365v-45H490V202.5h51.88"
              fill="none"
              stroke="#000"
              strokeMiterlimit={10}
              pointerEvents="stroke"
            />
            <path
              d="m548.88 202.5-7 3.5v-7Z"
              stroke="#000"
              strokeMiterlimit={10}
              pointerEvents="all"
            />
            <rect
              x={520}
              y={365}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 428,
                    marginLeft: 539,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.impact.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={630}
                y={431}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.impact.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M230 365v-45h430v-46.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m660 266.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M348.12 427.5h163.76"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m341.12 427.5 7-3.5v7ZM518.88 427.5l-7 3.5v-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={120}
              y={365}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 428,
                    marginLeft: 139,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.impact.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={230}
                y={431}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.impact.title4")}`}
              </text>
            </switch>
          </g>
          <g
            className=" flowchart-item outcome-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.outcome.text2.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.outcome.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.outcome.title2")}`)
            }
          >
            <rect
              x={120}
              y={597.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 660,
                    marginLeft: 139,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.outcome.title2")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={230}
                y={664}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.outcome.title2")}`}
              </text>
            </switch>
          </g>
          <path
            d="M760 660H598.12"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m591.12 660 7-3.5v7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M870 597.5v-53.75H698l-.02-46.76"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m697.98 489.99 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item impact-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.impact.text5.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.impact.text5.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.impact.title5")}`)
            }
          >
            <rect
              x={760}
              y={597.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 660,
                    marginLeft: 779,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.impact.title5")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={870}
                y={664}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.impact.title5")}`}
              </text>
            </switch>
          </g>
          <path
            d="M350 780v-20H230v-11.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m230 741.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={240}
              y={780}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 843,
                    marginLeft: 259,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.outcome.title3")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={350}
                y={846}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.outcome.title3")}`}
              </text>
            </switch>
          </g>
          <g className=" flowchart-item outcome-block">
            <rect
              x={355}
              y={959.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1022,
                    marginLeft: 374,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.outcome.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={465}
                y={1026}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.outcome.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M1126 950V660H988.12"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m981.12 660 7-3.5v7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item outcome-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.outcome.text5.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.outcome.text5.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.outcome.title5")}`)
            }
          >
            <rect
              x={1016}
              y={950}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1013,
                    marginLeft: 1035,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.outcome.title5")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={1126}
                y={1016}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.outcome.title5")}`}
              </text>
            </switch>
          </g>
          <path
            d="M860 1120v-100.5H598.12"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m591.12 1019.5 7-3.5v7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item output-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.output.text2.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.output.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.output.title2")}`)
            }
          >
            <rect
              x={750}
              y={1120}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1183,
                    marginLeft: 769,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.output.title2")}`}
                        <br
                          style={{
                            fontSize: 13,
                          }}
                        />
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={860}
                y={1186}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.output.title2")}`}
              </text>
            </switch>
          </g>
          <path
            d="M1126 1120v-38.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1126 1076.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item output-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.output.text3.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.output.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.output.title3")}`)
            }
          >
            <rect
              x={1016}
              y={1120}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1183,
                    marginLeft: 1035,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.output.title3")}`}
                        <br
                          style={{
                            fontSize: 13,
                          }}
                        />
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={1126}
                y={1186}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.output.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M1126 1680v-428.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1126 1246.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item output-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.output.text4.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.output.text4.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.output.title4")}`)
            }
          >
            <rect
              x={1020}
              y={1680}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1743,
                    marginLeft: 1039,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.output.title4")}`}
                        <br
                          style={{
                            fontSize: 13,
                          }}
                        />
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={1130}
                y={1746}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.output.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M860 1460v-36.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m860 1416.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item activity-block  flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.activity.text2.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.activity.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.activity.title2")}`)
            }
          >
            <rect
              x={750}
              y={1460}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1523,
                    marginLeft: 769,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={860}
                y={1526}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title2")}`}
              </text>
            </switch>
          </g>
          <path
            d="M860 1680v-40h211v-386.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1071 1246.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M860 1813.12v24.38h100v32.5"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m860 1806.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M970 1742.5h41.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1018.88 1742.5-7 3.5v-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block ">
            <rect
              x={750}
              y={1680}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1743,
                    marginLeft: 769,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={860}
                y={1746}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M720 1742.5h20-10 11.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m748.88 1742.5-7 3.5v-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item activity-block  flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.activity.text4.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.activity.text4.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.activity.title4")}`)
            }
          >
            <rect
              x={500}
              y={1680}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1743,
                    marginLeft: 519,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={610}
                y={1746}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M250 1742.5h-20 10-11.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m221.12 1742.5 7-3.5v7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M470 1742.5h20-10 11.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m498.88 1742.5-7 3.5v-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M360 1680v-60h500v-26.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m860 1586.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block ">
            <rect
              x={250}
              y={1680}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1743,
                    marginLeft: 269,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title5")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={360}
                y={1746}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title5")}`}
              </text>
            </switch>
          </g>
          <path
            d="M110 1680v-62h160v-53.88"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m270 1557.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.activity.text6.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.activity.text6.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.activity.title6")}`)
            }
          >
            <rect
              y={1680}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1743,
                    marginLeft: 19,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title6")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={110}
                y={1746}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title6")}`}
              </text>
            </switch>
          </g>
          <path fill="#c1f5c6" pointerEvents="all" d="M180 1850h480v160H180z" />
          <path
            d="M300 1867.5v-31.25h60v-23.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m360 1806.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item activity-block  flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.activity.text7.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.activity.text7.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.activity.title7")}`)
            }
          >
            <rect
              x={190}
              y={1867.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1930,
                    marginLeft: 209,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.activity.title7")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={300}
                y={1934}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title7")}`}
              </text>
            </switch>
          </g>
          <path
            d="M540 1867.5v-31.25H360v-23.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m360 1806.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={430}
              y={1867.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1930,
                    marginLeft: 449,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title8")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={540}
                y={1934}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title8")}`}
              </text>
            </switch>
          </g>
          <path
            d="M960 1867.5v-31.25h170v-23.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1130 1806.12 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M841.88 1930H658.12"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m848.88 1930-7 3.5v-7ZM651.12 1930l7-3.5v7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item activity-block  flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.activity.text9.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.activity.text9.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.activity.title9")}`)
            }
          >
            <rect
              x={850}
              y={1867.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1930,
                    marginLeft: 869,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title9")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={960}
                y={1934}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title9")}`}
              </text>
            </switch>
          </g>
          <path
            d="M270 1431v-173.25h-42v-165.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m228 1085.62 3.5 7h-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-relar.activity.text10.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-relar.activity.text10.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-relar.activity.title10")}`)
            }
          >
            <rect
              x={160}
              y={1431}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1494,
                    marginLeft: 179,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-relar.activity.title10")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={270}
                y={1497}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title10")}`}
              </text>
            </switch>
          </g>
          <path
            fill="#c1f5c6"
            pointerEvents="all"
            d="M464 1280h245v316.5H464z"
          />
          <path
            d="M704.62 1522.5H750"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m697.62 1522.5 7-3.5v7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={476.5}
              y={1460}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1523,
                    marginLeft: 496,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title11")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={587}
                y={1526}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title11")}`}
              </text>
            </switch>
          </g>
          <path
            d="M696.5 1352.5h45.38"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m748.88 1352.5-7 3.5v-7Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={476.5}
              y={1290}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1353,
                    marginLeft: 496,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-relar.activity.title12")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={587}
                y={1356}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-relar.activity.title12")}`}
              </text>
            </switch>
          </g>
          <image
            x={528.5}
            y={371.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={558.5}
            y={145.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={788.5}
            y={6.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          {/* <image
            x={127.5}
            y={371.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={125.5}
            y={604}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={369.5}
            y={603}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={768.5}
            y={603}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={1023.5}
            y={957}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={248.5}
            y={787.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={127.5}
            y={967.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={365.5}
            y={967.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={759.5}
            y={1127.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={1025.5}
            y={1127.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={759.5}
            y={1298.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          {/* <image
            x={485.5}
            y={1297.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={169.5}
            y={1438.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={485.5}
            y={1468.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={759.5}
            y={1464.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={9.5}
            y={1687.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={259.5}
            y={1687.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={508.5}
            y={1688.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={759.5}
            y={1687.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={1028.5}
            y={1687.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={859.5}
            y={1874.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          {/* <image
            x={439.5}
            y={1874.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          /> */}
          <image
            x={198.5}
            y={1874.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
        </svg>
      </section>
      <CSSTransition
        in={isModalOpen}
        timeout={200}
        classNames="modal"
        unmountOnExit
      >
        <Modal
          show={isModalOpen}
          setModal={setIsModalOpen}
          title={titleModal}
          inclusion={modalText_inclusion}
          digitalisation={modalText_digitalisation}
        />
      </CSSTransition>
    </motion.main>
  )
}

export default KA2Relar
