import React from "react"

export const Close = ({ fill = `none`, stroke = `#fff` }) => (
  <svg
    width={24}
    height={24}
    fill={fill}
    stroke={stroke}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
)

export const Back = ({ fill = `none`, stroke = `#fff` }) => (
  <svg
    width={25}
    height={25}
    fill={fill}
    stroke={stroke}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m15 18-6-6 6-6" />
  </svg>
)
export const ArrowRight = ({ fill = `none`, stroke = `#442033` }) => (
  <svg
    width={40}
    height={40}
    fill={fill}
    viewBox="0 0 24 24"
    stroke={stroke}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-arrow-right"
  >
    <path d="M5 12h14m-7-7 7 7-7 7" />
  </svg>
)
