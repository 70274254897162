import { React, useState } from "react"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import { CSSTransition } from "react-transition-group"
import Modal from "../components/modal"

const KA2Land = () => {
  const [t] = useTranslation("common")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText_inclusion, SetModalText_inclusion] = useState()
  const [modalText_digitalisation, SetModalText_digitalisation] = useState()
  const [titleModal, setTitleModal] = useState()

  return (
    <motion.main
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <section id="flowchart">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={931}
          height={2068}
          viewBox="-0.5 -0.5 931 2068"
        >
          <defs>
            <style>
              {"@import url(https://fonts.googleapis.com/css?family=Roboto);"}
            </style>
          </defs>
          <path
            d="M740 852h30V583.37"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m770 578.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M495 772v-28.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 738.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path fill="#c1f5c6" pointerEvents="all" d="M250 772h490v160H250z" />
          <g
            className="flowchart-item impact-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.impact.text1.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.impact.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.impact.title1")}`)
            }
          >
            <rect
              x={390}
              y={42}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 105,
                    marginLeft: 409,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.impact.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <g className=" flowchart-item impact-block">
            <rect
              x={660}
              y={222}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 285,
                    marginLeft: 679,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.impact.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <path
            d="M385 674.5H170v-41.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m170 628.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M495 612V284.5h158.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m658.88 284.5-7 3.5 1.75-3.5-1.75-3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M605 674.5h110v-91.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m715 578.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item outcome-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.outcome.text1.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.outcome.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.outcome.title1")}`)
            }
          >
            <rect
              x={385}
              y={612}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 675,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.outcome.title1")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <g className=" flowchart-item outcome-block">
            <rect
              x={270}
              y={789.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 852,
                    marginLeft: 289,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.outcome.title2")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <g
            className="flowchart-item outcome-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.outcome.text3.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.outcome.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.outcome.title3")}`)
            }
          >
            <rect
              x={500}
              y={789.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 852,
                    marginLeft: 519,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.outcome.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={610}
                y={856}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.outcome.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 1427v-20 10-13.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 1398.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.activity.text1.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.activity.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.activity.title1")}`)
            }
          >
            <rect
              x={385}
              y={1427}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1490,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.activity.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={495}
                y={1493}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.activity.title1")}`}
              </text>
            </switch>
          </g>
          <path
            d="M820 1172v-37.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m820 1129.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item output-block">
            <rect
              x={710}
              y={1172}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1235,
                    marginLeft: 729,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.output.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={820}
                y={1238}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.output.title1")}`}
              </text>
            </switch>
          </g>
          <path
            d="M170 152v-47.5h213.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m388.88 104.5-7 3.5 1.75-3.5-1.75-3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={60}
              y={152}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 215,
                    marginLeft: 79,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.impact.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <path
            d="M170 327v-43.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m170 278.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={60}
              y={327}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 390,
                    marginLeft: 79,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.impact.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <path
            d="M170 502v-43.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m170 453.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={60}
              y={502}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 565,
                    marginLeft: 79,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.impact.title5")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <path
            d="M770 452v-98.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m770 348.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item impact-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.impact.text6.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.impact.text6.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.impact.title6")}`)
            }
          >
            <rect
              x={660}
              y={452}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 515,
                    marginLeft: 679,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.impact.title6")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
            </switch>
          </g>
          <path
            d="M226.37 852.5H240l3.64-.18"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m221.12 852.5 7-3.5-1.75 3.5 1.75 3.5ZM248.88 852.06l-6.81 3.84 1.57-3.58-1.92-3.41Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M120 921.37v103.13h258.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m120 916.12 3.5 7-3.5-1.75-3.5 1.75ZM383.88 1024.5l-7 3.5 1.75-3.5-1.75-3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              y={790}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 853,
                    marginLeft: 19,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.outcome.title4")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={110}
                y={856}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.outcome.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 962v-23.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 933.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.outcome.text5.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.outcome.text5.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.outcome.title5")}`)
            }
          >
            <rect
              x={385}
              y={962}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />

            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1025,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.outcome.title5")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={495}
                y={1028}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.outcome.title5")}`}
              </text>
            </switch>
          </g>
          <path
            d="M710 1065.5h-52.5v-41h-46.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m606.12 1024.5 7-3.5-1.75 3.5 1.75 3.5Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={710}
              y={1003}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1066,
                    marginLeft: 729,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.outcome.title6")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={820}
                y={1069}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.outcome.title6")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 1117v-20 10-13.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 1088.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item outcome-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.outcome.text7.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.outcome.text7.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.outcome.title7")}`)
            }
          >
            <rect
              x={385}
              y={1117}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1180,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.outcome.title7")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={495}
                y={1183}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.outcome.title7")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 1272v-20 10-13.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 1243.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M605 1334.5h215v-31.13"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m820 1298.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item output-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.output.text2.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.output.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.output.title2")}`)
            }
          >
            <rect
              x={385}
              y={1272}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1335,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.output.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={495}
                y={1338}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.output.title2")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 1587v-20 5-13.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 1553.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path fill="#c1f5c6" pointerEvents="all" d="M250 1587h490v160H250z" />
          <g
            className=" flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.activity.text2.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.activity.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.activity.title2")}`)
            }
          >
            <rect
              x={270}
              y={1604.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1667,
                    marginLeft: 289,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.activity.title2")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={380}
                y={1671}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.activity.title2")}`}
              </text>
            </switch>
          </g>
          <g
            className=" flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen(prevState => !prevState) +
              SetModalText_inclusion(
                `${t("ka2-land.activity.text3.inclusion")}`
              ) +
              SetModalText_digitalisation(
                `${t("ka2-land.activity.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka2-land.activity.title3")}`)
            }
          >
            <rect
              x={500}
              y={1604.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1667,
                    marginLeft: 519,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka2-land.activity.title3")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={610}
                y={1671}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.activity.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 1782v-20 5-13.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 1748.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block ">
            <rect
              x={385}
              y={1782}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1845,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.activity.title4")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={495}
                y={1848}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.activity.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M495 1942v-20 5-13.63"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m495 1908.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#000"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block">
            <rect
              x={385}
              y={1942}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />

            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="100%"
                height="100%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 2005,
                    marginLeft: 404,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka2-land.activity.title5")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={495}
                y={2008}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka2-land.activity.title5")}`}
              </text>
            </switch>
          </g>

          <image
            x={393.5}
            y={619.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />

          <image
            x={509.5}
            y={800.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />

          <image
            x={399.5}
            y={49.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />

          <image
            x={669.5}
            y={460.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />

          <image
            x={393.5}
            y={971.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />

          <image
            x={393.5}
            y={1126.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={393.5}
            y={1280.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={279.5}
            y={1611.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={509.5}
            y={1611.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={393.5}
            y={1435.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
        </svg>
      </section>
      <CSSTransition
        in={isModalOpen}
        timeout={200}
        classNames="modal"
        unmountOnExit
      >
        <Modal
          show={isModalOpen}
          setModal={setIsModalOpen}
          title={titleModal}
          inclusion={modalText_inclusion}
          digitalisation={modalText_digitalisation}
        />
      </CSSTransition>
    </motion.main>
  )
}

export default KA2Land
