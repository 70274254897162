import { React, useState } from "react"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import { CSSTransition } from "react-transition-group"
import Modal from "../components/modal"

const KA1 = () => {
  const [t] = useTranslation("common")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText_inclusion, SetModalText_inclusion] = useState()
  const [modalText_digitalisation, SetModalText_digitalisation] = useState()
  const [titleModal, setTitleModal] = useState()

  return (
    <motion.main
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <section id="flowchart">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={1128}
          height={1931}
          viewBox="-0.5 -0.5 1128 1931"
        >
          <defs>
            <style>
              {"@import url(https://fonts.googleapis.com/css?family=Roboto);"}
            </style>
          </defs>
          <path
            fill="#c1f5c6"
            pointerEvents="all"
            d="M407 790.5h712v160H407zM160 1010h480v160H160z"
          />
          <path
            d="M355 560v-35.95H220.05l-.04-27.93"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m220 489.12 3.51 6.99-7 .01Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M355 560v-35.95h164v-27.93"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m519 489.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M355 728.12v27.09h340.42l-.06 34.81"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m355 721.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path fill="#c1f5c6" pointerEvents="all" d="M110 560h490v160H110z" />
          <path
            d="m400 1010 .05-37.53h130v-29.35"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m530.05 936.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="m867.66 949.38.08 61.41-.64 52.09"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m867.01 1069.88-3.41-7.04 7 .09Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M407 870.5H228.12"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m221.12 870.5 7-3.5v7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={468}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  dir="ltr"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 63,
                    marginLeft: 487,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka1.impact.title1")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={578}
                y={66}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.impact.title1")}`}
              </text>
            </switch>
          </g>
          <path
            d="m374 178 .05-115.53 85.83.03"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m466.88 62.5-7 3.5v-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item flowchart-modal impact-block"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.impact.text2.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.impact.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.impact.title2")}`)
            }
          >
            <rect
              x={264}
              y={178}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 241,
                    marginLeft: 283,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka1.impact.title2")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={374}
                y={244}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.impact.title2")}`}
              </text>
            </switch>
          </g>
          <path
            d="m220 363 .05-122.53 35.83.03"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m262.88 240.5-7 3.5v-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item impact-block">
            <rect
              x={110}
              y={363}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 426,
                    marginLeft: 129,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.impact.title3")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={220}
                y={429}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.impact.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M519 363V240.47l-26.88.02"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m485.12 240.5 7-3.51v7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item impact-block"
            // onClick={() =>
            // 	setIsModalOpen((prevState) => !prevState) +
            // 	SetModalText_inclusion(
            // 		`${t('ka1.impact.text4.inclusion')}`,
            // 	) +
            // 	SetModalText_digitalisation(
            // 		`${t('ka1.impact.text4.digitalisation')}`,
            // 	) +
            // 	setTitleModal(`${t('ka1.impact.title4')}`)
            // }
          >
            <rect
              x={409}
              y={363}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#d8127d"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 426,
                    marginLeft: 428,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 12,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {`${t("ka1.impact.title4")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={519}
                y={429}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={12}
                textAnchor="middle"
              >
                {`${t("ka1.impact.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M867 437V240.47l-374.88.03"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m485.12 240.5 7-3.5v7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M867 570.12V720"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m867 563.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item outcome-block">
            <rect
              x={757}
              y={437}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 500,
                    marginLeft: 776,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.outcome.title1")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={867}
                y={503}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.outcome.title1")}`}
              </text>
            </switch>
          </g>
          <g className=" flowchart-item outcome-block">
            <rect
              x={124}
              y={580}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 643,
                    marginLeft: 143,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.outcome.title2")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={234}
                y={646}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.outcome.title2")}`}
              </text>
            </switch>
          </g>
          <g
            className="flowchart-item flowchart-modal outcome-block"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.outcome.text3.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.outcome.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.outcome.title3")}`)
            }
          >
            <rect
              x={364}
              y={580}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#e5702a"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 643,
                    marginLeft: 383,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.outcome.title3")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={474}
                y={646}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.outcome.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="m110 933-.05 519.47 54.93.03"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m171.88 1452.5-7 3.5v-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item activity-block ">
            <rect
              y={808}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 871,
                    marginLeft: 19,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title2")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={110}
                y={874}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title2")}`}
              </text>
            </switch>
          </g>
          <g
            className="flowchart-item flowchart-modal output-block"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.output.text1.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.output.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.output.title1")}`)
            }
          >
            <rect
              x={173}
              y={1029.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1092,
                    marginLeft: 192,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.output.title1")}`}
                        <br
                          style={{
                            fontSize: 13,
                          }}
                        />
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={283}
                y={1096}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.output.title1")}`}
              </text>
            </switch>
          </g>
          <g
            className=" flowchart-item flowchart-modal output-block"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.output.text2.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.output.text2.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.output.title2")}`)
            }
          >
            <rect
              x={420}
              y={810}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 872,
                    marginLeft: 439,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.output.title2")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={530}
                y={876}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.output.title2")}`}
              </text>
            </switch>
          </g>
          <path
            d="M760 810v-90h230v90"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <g
            className=" flowchart-item output-block flowchart-modal"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.output.text3.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.output.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.output.title3")}`)
            }
          >
            <rect
              x={650}
              y={810}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 872,
                    marginLeft: 669,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.output.title3")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={760}
                y={876}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.output.title3")}`}
              </text>
            </switch>
          </g>
          <g
            className="flowchart-item flowchart-modal output-block"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.output.text4.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.output.text4.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.output.title4")}`)
            }
          >
            <rect
              x={880}
              y={810}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 872,
                    marginLeft: 899,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka1.output.title4")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={990}
                y={876}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.output.title4")}`}
              </text>
            </switch>
          </g>
          <g
            className="flowchart-item flowchart-modal output-block"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.output.text5.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.output.text5.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.output.title5")}`)
            }
          >
            <rect
              x={404}
              y={1028.5}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#3ea447"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1091,
                    marginLeft: 423,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {"Leraren gebruiken nieuwe vaardigheden"}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={514}
                y={1095}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {"Leraren gebruiken nieuwe vaa..."}
              </text>
            </switch>
          </g>
          <path
            d="m460 1240 .05-43.21h54l-.04-35.17"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m514 1154.62 3.51 6.99-7 .01Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item flowchart-modal activity-block "
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.activity.text1.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.activity.text1.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.activity.title1")}`)
            }
          >
            <rect
              x={350}
              y={1240}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1303,
                    marginLeft: 369,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title1")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={460}
                y={1306}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title1")}`}
              </text>
            </switch>
          </g>
          <path
            d="m393 1452.5 67.05-.03-.05-79.35"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m460 1366.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M283 1390v-227.38"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m283 1155.62 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.activity.text3.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.activity.text3.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.activity.title3")}`)
            }
          >
            <rect
              x={173}
              y={1390}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1453,
                    marginLeft: 192,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title3")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={283}
                y={1456}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title3")}`}
              </text>
            </switch>
          </g>
          <path
            d="M393 1648.5h111.88"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m511.88 1648.5-7 3.5v-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M283 1586v-64.63"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m283 1516.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item activity-block ">
            <rect
              x={173}
              y={1586}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1649,
                    marginLeft: 192,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title4")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={283}
                y={1652}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title4")}`}
              </text>
            </switch>
          </g>
          <path
            d="M283 1805v-87.63"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m283 1712.12 3.5 7-3.5-1.75-3.5 1.75Z"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block ">
            <rect
              x={173}
              y={1805}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1867,
                    marginLeft: 192,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title11")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={283}
                y={1871}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title11")}`}
              </text>
            </switch>
          </g>
          <path
            d="M503 1867.5H399.37"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m394.12 1867.5 7-3.5-1.75 3.5 1.75 3.5Z"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item activity-block ">
            <rect
              x={503}
              y={1805}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1867,
                    marginLeft: 522,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title5")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={613}
                y={1871}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title5")}`}
              </text>
            </switch>
          </g>
          <path
            d="M733 1648.5h165.88"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m905.88 1648.5-7 3.5v-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <path
            d="M623 1586v-109.95h77.05l-.05-101.93"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m700 1367.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className="flowchart-item activity-block ">
            <rect
              x={513}
              y={1586}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1649,
                    marginLeft: 532,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <p
                        style={{
                          lineHeight: 1.2,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {`${t("ka1.activity.title6")}`}
                      </p>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={623}
                y={1652}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title6")}`}
              </text>
            </switch>
          </g>
          <path
            d="M1017 1586v-62.88"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1017 1516.12 3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.activity.text7.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.activity.text7.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.activity.title7")}`)
            }
          >
            <rect
              x={907}
              y={1586}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1649,
                    marginLeft: 926,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title7")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={1017}
                y={1652}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title7")}`}
              </text>
            </switch>
          </g>
          <path
            d="M1017 1390v-227.53l-.58-221.48"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m1016.4 935.74 3.52 6.99-3.5-1.74-3.5 1.76Z"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className=" flowchart-item activity-block flowchart-modal"
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.activity.text8.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.activity.text8.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.activity.title8")}`)
            }
          >
            <rect
              x={907}
              y={1390}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1453,
                    marginLeft: 926,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      {`${t("ka1.activity.title8")}`}
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={1017}
                y={1456}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title8")}`}
              </text>
            </switch>
          </g>
          <path
            d="M867 1196v107.53l-48.88-.03"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m811.12 1303.5 7-3.5v7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g className=" flowchart-item activity-block ">
            <rect
              x={757}
              y={1071}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1134,
                    marginLeft: 776,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title9")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={867}
                y={1137}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title9")}`}
              </text>
            </switch>
          </g>
          <path
            d="m700 1241 .05-150.95-51.93-.04"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m641.12 1090 7-3.49-.01 7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <g
            className="flowchart-item flowchart-modal activity-block "
            onClick={() =>
              setIsModalOpen((prevState) => !prevState) +
              SetModalText_inclusion(`${t("ka1.activity.text10.inclusion")}`) +
              SetModalText_digitalisation(
                `${t("ka1.activity.text10.digitalisation")}`
              ) +
              setTitleModal(`${t("ka1.activity.title10")}`)
            }
          >
            <rect
              x={590}
              y={1241}
              width={220}
              height={125}
              rx={18.75}
              ry={18.75}
              fill="#00a3da"
              stroke="#6b6b6b"
              pointerEvents="all"
            />
            <switch transform="translate(-.5 -.5)">
              <foreignObject
                pointerEvents="none"
                width="101%"
                height="101%"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                style={{
                  overflow: "visible",
                  textAlign: "left",
                }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: "flex",
                    alignItems: "unsafe center",
                    justifyContent: "unsafe center",
                    width: 182,
                    height: 1,
                    paddingTop: 1304,
                    marginLeft: 609,
                  }}
                >
                  <div
                    data-drawio-colors="color: #FFFFFF;"
                    style={{
                      boxSizing: "border-box",
                      fontSize: 0,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: 13,
                        fontFamily: "Roboto",
                        color: "#fff",
                        lineHeight: 1.2,
                        pointerEvents: "all",
                        whiteSpace: "normal",
                        overflowWrap: "normal",
                      }}
                    >
                      <font
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`${t("ka1.activity.title10")}`}
                      </font>
                    </div>
                  </div>
                </div>
              </foreignObject>
              <text
                x={700}
                y={1307}
                fill="#FFF"
                fontFamily="Roboto"
                fontSize={13}
                textAnchor="middle"
              >
                {`${t("ka1.activity.title10")}`}
              </text>
            </switch>
          </g>

          <image
            x={270.5}
            y={184.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <path
            d="m648.12 1090.01 41.93.04V943.12"
            fill="none"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="stroke"
          />
          <path
            d="m641.12 1090 7-3.49-.01 7ZM690.05 936.12l3.5 7h-7Z"
            fill="#6b6b6b"
            stroke="#6b6b6b"
            strokeMiterlimit={10}
            pointerEvents="all"
          />
          <image
            x={370.5}
            y={587.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={426.5}
            y={817.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={657.5}
            y={816.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={888.5}
            y={816.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={178.5}
            y={1036.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={410.5}
            y={1035.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={914.5}
            y={1591.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={914.5}
            y={1397.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={597.5}
            y={1247.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={357.5}
            y={1247.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
          <image
            x={180.5}
            y={1397.5}
            width={20}
            height={20}
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgaGVpZ2h0PSIyMHB4IiB3aWR0aD0iMjBweCI+JiN4YTsgICAgPHRpdGxlPmljb25tb25zdHItaW5mby1maWxsZWQ8L3RpdGxlPiYjeGE7ICAgIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9Im5vbmUiIGlkPSJIb21lcGFnZSI+JiN4YTsgICAgICAgIDxnIGZpbGw9IiNGRkZGRkYiIGlkPSJpY29ubW9uc3RyLWluZm8tZmlsbGVkIj4mI3hhOyAgICAgICAgICAgIDxwYXRoIGlkPSJTaGFwZSIgZD0iTTkuOTk3LDAgQzE1LjUxNSwwIDE5Ljk5NSw0LjQ4IDE5Ljk5NSw5Ljk5NyBDMTkuOTk1LDE1LjUxNSAxNS41MTUsMTkuOTk1IDkuOTk3LDE5Ljk5NSBDNC40OCwxOS45OTUgMCwxNS41MTUgMCw5Ljk5NyBDMCw0LjQ4IDQuNDgsMCA5Ljk5NywwIEw5Ljk5NywwIFogTTkuOTk3LDggQzkuNTgzLDggOS4yNDcsOC4zMzYgOS4yNDcsOC43NSBMOS4yNDcsMTQuMjUgQzkuMjQ3LDE0LjY2NCA5LjU4MywxNSA5Ljk5NywxNSBDMTAuNDExLDE1IDEwLjc0NywxNC42NjQgMTAuNzQ3LDE0LjI1IEwxMC43NDcsOC43NSBDMTAuNzQ3LDguMzM2IDEwLjQxMSw4IDkuOTk3LDggWiBNOS45OTUsNSBDOS40NDMsNSA4Ljk5NSw1LjQ0OCA4Ljk5NSw2IEM4Ljk5NSw2LjU1MiA5LjQ0Myw3IDkuOTk1LDcgQzEwLjU0Nyw3IDEwLjk5NSw2LjU1MiAxMC45OTUsNiBDMTAuOTk1LDUuNDQ4IDEwLjU0Nyw1IDkuOTk1LDUgWiIvPiYjeGE7ICAgICAgICA8L2c+JiN4YTsgICAgPC9nPiYjeGE7PC9zdmc+"
            preserveAspectRatio="none"
          />
        </svg>
      </section>
      <CSSTransition
        in={isModalOpen}
        timeout={200}
        classNames="modal"
        unmountOnExit
      >
        <Modal
          show={isModalOpen}
          setModal={setIsModalOpen}
          title={titleModal}
          inclusion={modalText_inclusion}
          digitalisation={modalText_digitalisation}
        />
      </CSSTransition>
    </motion.main>
  )
}

export default KA1
