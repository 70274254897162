import { React, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { ArrowRight } from "../components/icons"
import styles from "../styles/overview.module.scss"

const OverviewPage = () => {
  const [t] = useTranslation("common")

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      document.querySelector("footer").style.display = "none"
    })
  }, [])
  return (
    <motion.main
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <section id="overview" className={styles.overview}>
        <div className={styles.container}>
          <h1 className="hidden">Overview</h1>

          <article>
            <Link reloadDocument to={t("nav.ka1_url")}>
              <h2>{t("general.KA1")}</h2>

              <ArrowRight />
            </Link>
          </article>
          <article>
            <Link reloadDocument to={t("nav.ka2_url_land")}>
              <h2>{t("general.KA2-land")}</h2>

              <ArrowRight />
            </Link>
          </article>
          <article>
            <Link reloadDocument to={t("nav.ka2_url_relar")}>
              <h2>{t("general.KA2-relar")}</h2>
              <ArrowRight />
            </Link>
          </article>
          <article>
            <Link reloadDocument to={t("nav.ka2_url_youth")}>
              <h2>{t("general.KA2-youth")}</h2>
              <ArrowRight />
            </Link>
          </article>
        </div>
      </section>
    </motion.main>
  )
}

export default OverviewPage
